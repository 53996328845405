<!-- eslint-disable vue/valid-template-root -->
<template></template>
<script>
import Service from '@/services/GlobalParameter/GlobalParameterService'

export default {
	name: 'EcmAutoRefresh',
	data: function () {
		return {
			service: Service
		}
	},
	mounted: function () {
		this.get()
	},
	methods: {
		recursiveGet: function () {
			setTimeout(() => {
				this.get()
			}, this.$envVar.VUE_APP_ECM_AUTOREFRESH_INTERVAL)
		},
		get: function () {
			this.service.getGlobalParameter('ecmAutoRefresh').then(() => {
				this.recursiveGet()
			})
		}
	}
}
</script>
